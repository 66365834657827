<template>
  <el-form
    :model="login"
    status-icon
    :rules="loginRules"
    ref="loginForm"
    @keyup.enter.native="onSubmit"
  >
    <el-form-item prop="userName">
      <el-input
        type="text"
        placeholder="用户名"
        v-model="login.userName"
        prefix-icon="el-icon-user"
      ></el-input>
    </el-form-item>
    <el-form-item prop="passWord">
      <el-input
        type="password"
        placeholder="密码"
        v-model="login.passWord"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button :loading="loading" @click="onSubmit">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { login } from "@/api/auth";
import { Form, FormItem, Input, Button } from "element-ui";
export default {
  name: "LoginForm",
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  data() {
    return {
      login: {
        userName: "",
        passWord: "",
      },
      loginRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.$refs["loginForm"]["validate"]((valid) => {
        if (valid) {
          this.loading = true;
          login({
            username: this.login.userName,
            password: this.login.passWord,
          })
            .then((rs) => {
              localStorage.setItem("SBCS-Token", rs.data.data.token);
              localStorage.setItem(
                "SBCS-User-Info",
                JSON.stringify(rs.data.data.User)
              );
              this.$router.push({ path: "/" });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
