<template>
  <div class="vv-login">
    <el-container>
      <el-main>
        <el-row>
          <el-col :span="24">
            <div class="vv-login-form">
              <div class="vv-logo">学生公交扫码系统</div>
              <login-form />
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { Container, Main, Row, Col } from "element-ui";
import loginForm from "./form";
export default {
  name: "Login",
  components: {
    [Container.name]: Container,
    [Main.name]: Main,
    [Row.name]: Row,
    [Col.name]: Col,
    loginForm,
  },
};
</script>
